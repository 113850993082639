import axios from '@/axios'
//const route = 'budget/cost'
export default {
    getAll(ItemsPerPage, page, search) {
        let route = 'helper/get_all_cost'
        return axios.get(route + '?page=' + page + '&items_per_page=' + ItemsPerPage + '&search=' + search)
    },

    getInputs(cost_id, stage_id) {
        const route = `report/input/inputs/${cost_id}/${stage_id}`
        return axios.get(route)
    },

    getStages(budget_id) {
        const route = 'report/input/stages/'
        return axios.get(route + budget_id)
    },

    getAbcInputs(cost_id,stage_id) {
        return axios.get(`report/input/abc/${cost_id}/${stage_id}`)
    },
    
    getAbcInputsSelectStages(cost_id) {
        return axios.post(`report/input/abc/stages/${cost_id}`)
    },
    
    ExportAbcInputs(cost_id, stage_id) {
        return axios.get(`report/input/abc_export/${cost_id}/${stage_id}` , {
            responseType: 'blob'
        })
    },
    getSelectStages(cost_id) {
        const route = 'report/input/abc_export/'
        return axios.get(route + cost_id, {
            responseType: 'blob'
        })
    },


    exportExcel(cost_id, stage_id) {
        const route = `report/input/inputs/export_excel/${cost_id}/${stage_id}`
        return axios.get(route, { responseType: 'blob' })
    },

    getInputTotal(cost_id) {
        const route = 'report/input/total/'
        return axios.get(route + cost_id)
    },

    exportStageExcel(cost_id) {
        const route = `report/input/export_stage_excel/${cost_id}`
        return axios.get(route ,{responseType: 'blob'})
    },

    ExportExcelInputsTotal(cost_id) {
        const route = `report/input/total_export_excel/${cost_id}`
        return axios.get(route ,{responseType: 'blob'})
    },


}
