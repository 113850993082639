<template>
    <v-container>

        <v-data-table :loading="loading_data_table" :headers="headers" :items="costs" :pageCount="numberOfPagesPaginate"
             :page="currentPagePaginate" :server-items-length="totalStagesPaginate" :options.sync="options"
            class="elevation-1" :footer-props="{
                itemsPerPageText: 'Itens por pagina',
                itemsPerPageOptions: [5, 10, 15],
                showFirstLastPage: true
            }">
            <template v-slot:item.item="{ item }">
                {{ parseFloat(item.item) }}
            </template>

            <template v-slot:item.value="{ item }">
                {{ formatMoney(item.value) }}
            </template>
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Custos</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Localizar" @click:append="searchItems()"
                        @change="searchItems()" single-line hide-details class="mr-8"></v-text-field>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" @click="showReportInputPerStage(item)" v-bind="attrs" v-on="on"> mdi-altimeter </v-icon>
                    </template>
                    <span>Etapas</span>
                </v-tooltip>
            </template>
            <template v-slot:no-data> Nenhum registro encontrado </template>
        </v-data-table>
    </v-container>
</template>


<script>
import service from "./service";
import { mapMutations } from 'vuex'
export default {
    data: () => ({
        search: "",
        totalStagesPaginate: 0,
        numberOfPagesPaginate: 0,
        currentPagePaginate: 0,
        options: {},
        headers: [
            { text: "Nome", value: "name", align: "start", sortable: false },
            { text: "", value: "actions", sortable: false, align: "end" },
        ],
        costs: [],

    }),

    computed: {
    },

    watch: {
        options: {
            handler() {
                this.initialize();
            },
        },
    },

    created() {
        this.initialize();
    },

    methods: {
        ...mapMutations('ReportStage', ['setCostId']),

        initialize() {
            this.loading_data_table = true;
            const { page, itemsPerPage } = this.options;
            service.getAll(itemsPerPage || 10, page, this.search).then((response) => {
                this.costs = response.data.data;
                this.totalStagesPaginate = response.data.total
                this.numberOfPagesPaginate = response.data.last_page
                this.currentPagePaginate = response.data.current_page
                this.loading_data_table = false;
            });
        },

        searchItems() {
            this.options = {}
            this.initialize()
        },

        showReportInputPerStage(cost){
            this.$router.push('/system/report/input/stages/' + cost.id)
        },

        formatMoney(number) {
            number = this.parseNumber(number)
            return number.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        },

        parseNumber(number) {
            if (isNaN(number)) { return 0; }
            let result = parseFloat(number);
            return result;
        },

    },
};
</script>


